import { React, useState } from 'react'
export const EPKcontact = (props) => {
  return (
    <div>
      <div id='bio'>
        <div className='container'>
            <div className='col-md-12'>
            <h2>Contacto</h2>
            </div> 
            <div className='col-md-12'>
                <h3>José Romero</h3>
                <h3><a href='mailTo:gororiffs@gmail.com'>gororiffs@gmail.com</a></h3>
                <h3>(+593) 0998179743</h3>
            </div>
        </div>
      </div>
    </div>
  )
}
