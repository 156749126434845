import React from 'react';
export const Subnav = (props) => {
  return (
    <nav id='subnav' className='navbar navbar-default navbarCustom'>
      <div className='container navbarContainer'>
          <ul className='nav navbar-nav'>  
            <li>
              <a href='#aboutbio'>
                {props.data ? props.data.bioLink : 'Bio'}
              </a>
            </li>
            <li>
              <a href='#tour'>
                {props.data ? props.data.tourLink : 'Tour'}
              </a>
            </li>
            <li>
              <a href='#music'>
                {props.data ? props.data.musicLink : 'Music'}
              </a>
            </li>
            <li>
              <a href='#videos'>
                Videos
              </a>
            </li>
            <li>
              <a href='#links'>
                {props.data ? props.data.links : 'More'}
              </a>
            </li>
          </ul>
        </div>
    </nav>
  )
}
