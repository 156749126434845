import React from 'react';
export const Homebio = (props) => {
  return (
    <div id='homebio' className='text-center'>
      <div className='container featuresContainer'>
        <div className='col-md-6 col-md-offset-3 col-xs-12 section-title'>
          <h2>{props.data ? props.data.title : 'Bio'}</h2>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6 col-md-offset-3">
            <div className="about-text">
                <p>{props.data ? props.data.paragraph1 : 'GORO es un power trío de Stoner Rock formado por músicos con décadas de trayectoria en la música extrema de Ecuador. Inspirados por Black Sabbath, High on Fire y Voivod, llevan dos años de existencia preparando su primer EP, el cual tendrá cinco canciones originales, en las que se acoplan diferentes estilos, abarcando del Blues al Death Metal, con el sonido distintivo de la banda.'}</p>
                <p>{props.data ? props.data.paragraph2 : 'Los integrantes de GORO colectivamente han contribuido a más de una docena de lanzamientos discográficos; habiendo recorrido muchos escenarios dentro y fuera del Ecuador, compartiendo con bandas de todo el mundo en algunos de los festivales más grandes de la región.'}</p>
                <p>{props.data ? props.data.paragraph3 : 'En los próximos meses la banda se prepara para lanzar nueva música en un nuevo formato, el cual ofrecerá una experiencia narrativa audiovisual que expande las temáticas del disco, combinando todos los recursos artísticos a disposición de GORO. Se trata de un disco en formato de cómic, el cual abre la puerta a un universo narrativo original de Ciencia Ficción.'}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
