import React from 'react';
export const Links = (props) => {
  return (
    <div id='links' className='text-center'>
      <div className='container featuresContainer'>
          <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a target='_blank' rel="noopener noreferrer" href={props.data ? props.data.tiktok : '/'}>
                      <img src="img/tt_w.png" className="linkIcon" alt="" />{" "}
                        <h3>TikTok</h3>
                    </a>
                  </li>
                  <li>
                    <a target='_blank' rel="noopener noreferrer" href={props.data ? props.data.instagram : '/'}>
                      <img src="img/ig_w.png" className="linkIcon" alt="" />{" "}
                        <h3>Instagram</h3>
                    </a>
                  </li>
                  <li>
                    <a target='_blank' rel="noopener noreferrer" href={props.data ? props.data.youtube : '/'}>
                      <img src="img/yt_w.png" className="linkIcon" alt="" />{" "}
                        <h3>YouTube</h3>
                    </a>
                  </li>
                  <li>
                    <a target='_blank' rel="noopener noreferrer" href={props.data ? props.data.bandcamp : '/'}>
                      <img src="img/bc_w.png" className="linkIcon" alt="" />{" "}
                        <h3>Bandcamp</h3>
                    </a>
                  </li>
                  <li>
                    <a target='_blank' rel="noopener noreferrer" href={props.data ? props.data.facebook : '/'}>
                      <img src="img/fb_w.png" className="linkIcon" alt="" />{" "}
                        <h3>Facebook</h3>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </div>
    </div>
  )
}
