import { React, useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Subnav } from "./components/subnav";
import { Bioheader } from "./components/bioheader";
import { Homebio } from "./components/Homebio";
import { Tour } from "./components/tour";
import { Comic } from "./components/comic";
import { Music } from "./components/music";
import { Homeband } from "./components/Homeband";
import { Links } from "./components/links";
import { Contact } from "./components/contact";
//import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const HomePage = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [currentLanguage, setCurrentLanguage] = useState('en'); // Default language is English
    
  useEffect(() => {
    // Load data based on the current language
    const dataPath = currentLanguage === 'en' ? 'data_en.json' : 'data_es.json';
    import(`./data/${dataPath}`).then((jsonData) => {
      setLandingPageData(jsonData.default);
    });
  }, [currentLanguage]);

  const toggleLanguage = () => {
    setCurrentLanguage(currentLanguage === 'en' ? 'es' : 'en');
  };    

  return (
    <div>
      <button onClick={toggleLanguage} className={"languageButton"}>EN / ES</button>
      <Navigation data={landingPageData.Navigation} />
      <Bioheader data={landingPageData.Bioheader} />
      <Homebio data={landingPageData.Aboutbio} />
      <Music data={landingPageData.MusicFeatures} />
      <Comic data={landingPageData.Comic} />
      <Homeband data={landingPageData.Homeband} />
      <Links data={landingPageData.Features} />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default HomePage;
