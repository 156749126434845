import React from 'react';
export const WolfLyrics = (props) => {
  return (
    <div id="wolflyrics">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <h2>{props.data ? props.data.title : 'Lyrics'}</h2>
                <p><b>The Wolf You Feed</b></p>
                <p>Frozen<br/>
                Sleepless<br/>
                Hungry</p>

                <p>For you<br/>
                The world was rendered<br/>
                To fall<br/>
                You now deserve<br/>
                You have no justice<br/>
                In your heart<br/>
                All falls apart<br/>
                Back to the start</p>

                <p>The wolf is coming from the forest<br/>
                Take what<br/>
                You need<br/>
                Take the hunt across the waters<br/>
                The wolf you want<br/>
                The wolf you feed</p>

                <p>Your destroying<br/>
                Mother Earth<br/>
                Won't be suffered<br/>
                For much more</p>

                <p>In the running<br/>
                Friends like me<br/>
                We'll come tearing<br/>
                Bone from meat</p>

                <p>The wolf is coming from the forest<br/>
                Their howls<br/>
                Are near<br/>
                Take the hunt across the waters<br/>
                Take what<br/>
                You need</p>

                <p>The water carries away the bodies<br/>
                The water runs right to the sea<br/>
                The wolf keeps running<br/>
                The wolf you want<br/>
                The wolf you feed</p>

                <p>Frozen<br/>
                Sleepless<br/>
                Hungry</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
