import React from 'react';
export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
                <div className='video-header-container'>
                    <video className='video-responsive video-header' autoPlay loop muted  poster='img/wolfheader.png'>
                      <source src='video/wolf.mp4' type='video/mp4' />
                      <source src='video/wolf.webm' type='video/webm' />
                      Su navegador no soporta video.
                    </video>
                </div>
        </div>
      </div>
    </header>
  )
}
