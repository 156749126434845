import React from 'react';
export const Music = (props) => {
  return (
    <div id='music' className='text-center'>
      <div className='container musicContainer'>
        <div className="row">
          <div className='col-md-8 col-md-offset-2 section-title'>
            <h2>{props.data ? props.data.musicTitle : "Last Wave of Summer"}</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img src="img/lastwaveofsummer.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className='row'>
                <div className="about-text">
                <p>{props.data ? props.data.p1 : "En su EP debut Goro se embarca en una expedición donde los riffs rugen, las voces resuenan y la creatividad prospera. La versatilidad de Goro se muestra en la odisea de géneros que abarca Stoner, Thrash y Punk, un verdadero testimonio de la destreza ecléctica de la banda. Acompañado de una obra en formato de cómic digital e impreso, el disco ofrece una experiencia visual y narrativa que transmite y expande las ideas de la música."}</p>
                <ol>
                <li>
                <a href="/wave">1. Last Wave of Summer</a>
                </li>
                <li>
                <a href="/emperor">2. Emperor Prophet</a>
                </li>
                <li>
                <a href="/wage">3. Wage Slave</a>
                </li>
                <li>
                <a href="/tomorrow">4. Tomorrow Never Comes</a>
                </li>
                <li>
                <a href="/wolf">5. The Wolf You Feed</a>
                </li>
                </ol>
                </div>
            </div>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.bandcamp : '/'}>
                      <img src="img/bc_b.png" className="linkIcon" alt="" />{" "}
                        <h3>Bandcamp</h3>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.spotify : '/'}>
                      <img src="img/sp_b.png" className="linkIcon" alt="" />{" "}
                        <h3>Spotify</h3>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <img src="img/yt_b.png" className="linkIcon" alt="" />{" "}
                        <h3>YouTube</h3>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className='col-md-6'>
            <iframe src="https://open.spotify.com/embed/album/5u9ZxpvfiZJ11E6KpEpAqU?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
          <div className='col-md-6 videoHolder'>
            <iframe src="https://www.youtube.com/embed/videoseries?si=Cnf2KOo9kOxk2aDI&amp;controls=0&amp;list=OLAK5uy_kvLO8A2WLE-PGNWAWCJymsh9qPKO1XKQg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}
