import React from 'react';
export const SubnavWage = (props) => {
  return (
    <nav id='submenu' className='navbar navbar-default navbarCustom'>
      <div className='container navbarContainer'>
          <ul className='nav navbar-nav'>  
            <li>
              <a href='#wavemusic'>
                {props.data ? props.data.musicLink : 'Music'}
              </a>
            </li>
            <li>
              <a href='#wavelyrics'>
                {props.data ? props.data.lyricsLink : 'Lyrics'}
              </a>
            </li>
            <li>
              <a href='#wolfFeatures'>
                {props.data ? props.data.featuresLink : 'More'}
              </a>
            </li>
          </ul>
        </div>
    </nav>
  )
}
