import React from 'react';
export const WolfFeatures = (props) => {
  return (
    <div id='wolfFeatures' className='text-center'>
      <div className='container featuresContainer'>
        <div className="row">
          <div className="col-md-6 col-s-12">
            <img src="img/lastwaveofsummer.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-md-6 col-s-12">
            <div className='row'>
                <div className="about-text">
                  <h2>{props.data ? props.data.title : "'Last Wave of Summer' is coming"}</h2>
    <p>{props.data ? props.data.p1 : "Goro's journey unfurls through a series of groundbreaking steps. An audible alchemy that sees Jose's transformation extending beyond strings, infusing the band's identity with unique vocals. By collaborating with visual artists, the band forges a distinct visual identity, harmonizing the essence of their music. The masterful mix by Derek 'Doomdaddy' Mattin elevates their soundscapes, propelling Goro's sonic realms to extraordinary heights."}</p>
    <p>{props.data ? props.data.p2 : "As Goro ascends, an era of artistic exploration beckons. Goro's blazing versatility is showcased in the genre odyssey of their debut EP that spans Stoner, Thrash, and Punk, a true testament to the band’s eclectic prowess. Unified themes in their future releases promise to weave diverse styles into thematic cohesion, drawing parallels with the journey undertaken by King Gizzard and the Lizard Wizard. Lastly, collaborative harmony is sought after as they look for allies to join them in live performances and collective creation, ensuring Goro's melodies resound far and wide."}</p>
                </div>
            </div>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a target='_blank' rel="noreferrer" href={props.data ? props.data.spotify : '/'}>
                      <img src="img/sp_w.png" className="linkIcon" alt="" />{" "}
                        <h3>Spotify</h3>
                    </a>
                  </li>
                  <li>
                    <a target='_blank' rel="noreferrer" href={props.data ? props.data.bandcamp : '/'}>
                      <img src="img/bc_w.png" className="linkIcon" alt="" />{" "}
                        <h3>Bandcamp</h3>
                    </a>
                  </li>
                  <li>
                    <a target='_blank' rel="noreferrer" href={props.data ? props.data.youtube : '/'}>
                      <img src="img/yt_w.png" className="linkIcon" alt="" />{" "}
                        <h3>YouTube</h3>
                    </a>
                  </li>
                  <li>
                    <a target='_blank' rel="noreferrer" href={props.data ? props.data.tiktok : '/'}>
                      <img src="img/tt_w.png" className="linkIcon" alt="" />{" "}
                        <h3>TikTok</h3>
                    </a>
                  </li>
                  <li>
                    <a target='_blank' rel="noreferrer" href={props.data ? props.data.instagram : '/'}>
                      <img src="img/ig_w.png" className="linkIcon" alt="" />{" "}
                        <h3>Instagram</h3>
                    </a>
                  </li>
                  <li>
                    <a target='_blank' rel="noreferrer" href={props.data ? props.data.facebook : '/'}>
                      <img src="img/fb_w.png" className="linkIcon" alt="" />{" "}
                        <h3>Facebook</h3>
                    </a>
                  </li>
                  <li>
                    <a target='_blank' rel="noreferrer" href={props.data ? props.data.discord : '/'}>
                      <img src="img/dc_w.png" className="linkIcon" alt="" />{" "}
                        <h3>Discord</h3>
                    </a>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
