import React from 'react';
export const Tour = (props) => {
  return (
    <div id="tour">
      <div className="container">
        <div className='col-md-6 col-xs-12 section-title'>
          <h2>{props.data ? props.data.title : 'Concerts'}</h2>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
                <div className="row">
                  <div className="col-xs-6 col-md-3">
                      <center>
                      <img src="img/quito-23-12-23.jpeg" className="img-responsive" alt="" />{" "}
                      </center>
                  </div>
                  <div className="col-xs-6 col-md-3">
                      <center>
                      <img src="img/witch-27-10-23.jpeg" className="img-responsive" alt="" />{" "}
                      </center>
                  </div>
                  <div className="col-xs-6 col-md-3">
                      <center>
                      <img src="img/ambato-01-09-23.jpg" className="img-responsive" alt="" />{" "}
                      </center>
                  </div>
                  <div className="col-xs-6 col-md-3">
                      <center>
                      <img src="img/guayaquil-15-04-23.jpeg" className="img-responsive" alt="" />{" "}
                      </center>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
