import React from 'react';
export const Homeband = (props) => {
  return (
    <div id='homeband' className='text-center'>
      <div className='container featuresContainer'>
        <div className='col-md-6 col-md-offset-3 col-xs-12 section-title'>
          <img src="/img/ampheader.png" className="video" />
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6 col-md-offset-3">
            <div className="about-text">
                <p>{props.data ? props.data.p1 : 'GORO es un power trío de Stoner Rock formado por músicos con décadas de trayectoria en la música extrema de Ecuador. Inspirados por Black Sabbath, High on Fire y Voivod, llevan dos años de existencia preparando su primer EP, el cual tendrá cinco canciones originales, en las que se acoplan diferentes estilos, abarcando del Blues al Death Metal, con el sonido distintivo de la banda.'}</p>
                <p>{props.data ? props.data.p2 : 'Los integrantes de GORO colectivamente han contribuido a más de una docena de lanzamientos discográficos; habiendo recorrido muchos escenarios dentro y fuera del Ecuador, compartiendo con bandas de todo el mundo en algunos de los festivales más grandes de la región.'}</p>
                <p>
                  <a href={props.data ? props.data.l1 : ''}>{props.data ? props.data.a1 : ''}</a><br />
                  <a href={props.data ? props.data.l2 : ''}>{props.data ? props.data.a2 : ''}</a><br />
                  <a href={props.data ? props.data.l3 : ''}>{props.data ? props.data.a3 : ''}</a>
                </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-md-6 col-md-offset-3">
              <img src="/img/live.jpg" className="video" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
