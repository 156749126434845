import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { SubnavWolf } from "./components/SubnavWolf";
import { Header } from "./components/header";
import { ListenLinksWolf } from "./components/ListenLinksWolf";
import { VideoWolf } from "./components/VideoWolf";
import { WolfLyrics } from "./components/WolfLyrics";
import { WolfFeatures } from "./components/WolfFeatures";
import { Newsletter } from "./components/newsletter";
//import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const WolfPage = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [currentLanguage, setCurrentLanguage] = useState('en'); // Default language is English
    
  useEffect(() => {
    // Load data based on the current language
    const dataPath = currentLanguage === 'en' ? 'data_en.json' : 'data_es.json';
    import(`./data/${dataPath}`).then((jsonData) => {
      setLandingPageData(jsonData.default);
    });
  }, [currentLanguage]);

  const toggleLanguage = () => {
    setCurrentLanguage(currentLanguage === 'en' ? 'es' : 'en');
  };    
    
  return (
    <div>
      <button onClick={toggleLanguage} className={"languageButton"}>EN / ES</button>
      <Navigation data={landingPageData.Navigation} />
      <SubnavWolf data={landingPageData.SubnavWolf} />
      <Header data={landingPageData.Header} />
      <ListenLinksWolf data={landingPageData.ListenLinksWolf} />
      <VideoWolf data={landingPageData.Team} />
      <WolfLyrics data={landingPageData.WolfLyrics} />
      <WolfFeatures data={landingPageData.WolfFeatures} />
      <Newsletter data={landingPageData.Newsletter} />
    </div>
  );
};

export default WolfPage;
