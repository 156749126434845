import React from 'react';
export const EPKdownloads = () => {
    const handleDownload = (fileUrl, fileName) => {
    // Assuming you have the file URL, you can create a link and trigger a click event to download the file
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    link.target = '_blank';
    link.click();
  };
    
  return (
    <div id="epkdownloads">
      <div className="container">
          <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Descargas</h2>
        </div>
        <div className="row">
          <div className="col-xs-6 col-md-4 col-md-offset-2">
            <button className="download-btn" onClick={() => handleDownload('https://www.gororiffs.com/fotos.zip','fotos.zip')}>Fotos</button>
          </div>
          <div className="col-xs-6 col-md-4">
            <button className="download-btn" onClick={() => handleDownload('https://www.gororiffs.com/goropresskit.pdf','goropresskit.pdf')}>PDF</button>
          </div>
        </div>
      </div>
    </div>
  );
};