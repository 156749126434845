import React from 'react';
export const Comic = (props) => {
  return (
    <div id='comic' className='text-center'>
      <div className='container musicContainer'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>{props.data ? props.data.comicTitle : "Album + Comic"}</h2>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <div className='row'>
                <div className="about-text">
                <p>{props.data ? props.data.p1 : "Goro's journey unfurls through a series of groundbreaking steps. By collaborating with visual artists, the band forges a distinct visual identity, harmonizing the essence of their music. The music led to the creation of multiple stories that come together, and a unique digital and physical format for the album."}</p>
                <p>{props.data ? props.data.p2 : "Each song is accompanied by a short story in comic format that intertwines to create an epic science fiction narrative, complementing and expanding upon the themes of the music. By working with different artists from across the world, the stories came to life visually to create an experience that goes with the listening of the album."}</p>
                <p>{props.data ? props.data.p3 : "The comic is available as a digital download that can be purchased on its own, as well as along with the download of the music. There will also be print versions that will include a digital download, which will be available in limited editions by direct purchase."}</p>
                <p><a href="#">{props.data ? props.data.a1 : "Purchase comic (digital)"}</a></p>
                <p><a href="#">{props.data ? props.data.a2 : "Purchase comic + music (Bandcamp)"}</a></p>
                </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <img src="img/comic.jpg" className="img-responsive" alt="" />{" "}
          </div>
        </div>
      </div>
    </div>
  )
}
