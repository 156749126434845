import React from 'react';
export const EPKmusic = (props) => {
  return (
    <div id='epkmusic' className='text-center'>
      <div className='container musicContainer'>
          <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Música</h2>
        </div>
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <img src="img/lastwaveofsummer.jpg" className="img-responsive" alt="" />{" "}
              <p>Además de distribuirlo en todas las plataformas de streaming y descarga, el disco tendrá una versión física en formato Cómic, que también estará disponible digitalmente. Este Cómic expande la experiencia del disco, e introduce un nuevo universo narrativo inspirado y basado en la música y letras del disco.</p>
          </div>
          <div className="col-xs-12 col-md-6">
            <div className='row'>
                <div className="about-text">
                  <h2>'Last Wave of Summer' EP</h2>
                    
                  <iframe src="https://open.spotify.com/embed/track/1MUWSIbbPCPrPJA4oy23AI?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                
                <iframe src="https://open.spotify.com/embed/track/4aKW9eBBIFKcIOLlIUNHFV?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                <iframe src="https://open.spotify.com/embed/track/5xCfL53EfLsDur3lGLipA7?utm_source=generator" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
                    </div>
            </div>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.spotify : '/'}>
                      <img src="img/sp_s.png" className="linkIcon" alt="" />{" "}
                        <h3>Spotify</h3>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.bandcamp : '/'}>
                      <img src="img/bc_s.png" className="linkIcon" alt="" />{" "}
                        <h3>Bandcamp</h3>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <img src="img/yt_s.png" className="linkIcon" alt="" />{" "}
                        <h3>YouTube</h3>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
