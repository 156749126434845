import React from 'react';
export const VideoWolf = (props) => {
  return (
    <div id='wolfvideo' className='text-center'>
      <div className='container'>
        <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>VIDEO</h2>
        </div>
        <div id='row'>
          <div className='col-md-12 col-s-12'>
            <iframe
              class="largevideo"
              title="wolfVideo"
              src="https://www.youtube.com/embed/QlWqPMR91mU"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen></iframe>
          </div>
        </div>
      </div>
    </div>
  )
}
