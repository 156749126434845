import { React, useState, useEffect } from "react";
import { EPKheader } from "./components/epkheader";
import { EPKvideo } from "./components/epkvideo";
import { EPKmusic } from "./components/epkmusic";
import { EPKconcerts } from "./components/epkconcerts";
import { EPKdownloads } from "./components/epkdownloads";
import { EPKbio } from "./components/epkbio";
import { EPKcontact } from "./components/epkcontact";
//import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const EPKPage = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [currentLanguage, setCurrentLanguage] = useState('en'); // Default language is English
    
  useEffect(() => {
    // Load data based on the current language
    const dataPath = currentLanguage === 'en' ? 'data_en.json' : 'data_es.json';
    import(`./data/${dataPath}`).then((jsonData) => {
      setLandingPageData(jsonData.default);
    });
  }, [currentLanguage]);

  const toggleLanguage = () => {
    setCurrentLanguage(currentLanguage === 'en' ? 'es' : 'en');
  };    

  return (
    <div>
      <EPKheader />
      <EPKvideo />
      <EPKmusic />
      <EPKconcerts />
      <EPKbio />
      <EPKdownloads />
      <EPKcontact />
    </div>
  );
};

export default EPKPage;
