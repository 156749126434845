import React from 'react';
export const EPKconcerts = () => {
  return (
    <div id="epkconcerts">
      <div className="container">
          <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Conciertos</h2>
        </div>
        <div id='row'> 
            <div className='col-md-8 col-md-offset-2'>
                <p>Desde 2022, la banda ha estado activa en escenarios junto a bandas de todo el Ecuador:</p>

<ul>
  <li><strong>7/05/22:</strong> Ambato junto a Notoken, Nada A Cambio</li>
  <li><strong>19/08/22:</strong> Festival Internacional Quito Blues</li>
  <li><strong>29/10/22:</strong> Quito junto a Perdido En Mi, Trovador Depresivo</li>
  <li><strong>15/04/23:</strong> Guayaquil junto a Replika, Cortopunzante</li>
  <li><strong>13/05/23:</strong> Otavalo junto a Raam, Witch</li>
  <li><strong>04/11/23:</strong> Riobamba junto a Balrok, DMT</li>
  <li><strong>23/12/23:</strong> Quito junto a Killme, Delirio</li>
</ul>

<p>La banda presenta un show completo en el que sale a relucir toda la experiencia de los músicos en escena, incorporando la diversidad musical de la banda con interpretaciones e improvisaciones que hacen de cada concierto una experiencia única.</p>
            </div>
          </div>
          <div id='row'> 
            <div className='col-md-10 col-md-offset-1 col-s-1'>
                <img src="img/live.jpeg"></img>
            </div>
          </div>
        </div>
      </div>
  );
};