import React from 'react';
export const EmperorLyrics = (props) => {
  return (
    <div id="wavelyrics">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <h2>{props.data ? props.data.title : 'Lyrics'}</h2>
                <p><b>Emperor Prophet</b></p>
                <p>Bid you come together<br/>
                Pray you heed my words<br/>
                I can smell your fear<br/>
                I can taste your blood</p>

                <p>I came from the mountains<br/>
                Back from when you’re heading to<br/>
                I tread and shake the earth<br/>
                I am the only path</p>

                <p>Emperor<br/>
                Prophet</p>

                <p>Who are you the nameless?<br/>
                I don’t know your face<br/>
                Why do you pain and fray<br/>
                I don’t know your name<br/>
                I know<br/>
                What you will never know<br/>
                I guess<br/>
                You’ll just have to trust me</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
