import React from 'react';
export const TomorrowLyrics = (props) => {
  return (
    <div id="wavelyrics">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <h2>{props.data ? props.data.title : 'Lyrics'}</h2>
                <p><b>Tomorrow Never Comes</b></p>
                <p>Once upon a time<br/>
                We shared a story<br/>
                Partners in crime<br/>
                Things were different between us<br/>
                In a previous life</p>

                <p>We shared a stage<br/>
                A taste of glory<br/>
                Nights days exchanged<br/>
                All just a distant memory<br/>
                Is it gone?</p>

                <p>Another day in the running<br/>
                Another race to run<br/>
                Live fast just for today<br/>
                Tomorrow never comes</p>

                <p>Across the length of time<br/>
                Across the endless worlds<br/>
                We got to share in a warm embrace<br/>
                We stood together as the wave was crashing</p>
                
                <p>You know we could’ve been better<br/>
                Yeah but we did alright <br/>
                The twists and turns that would come our way<br/>
                They still would take us far</p>

                <p>Another day in the running<br/>
                Another race to run<br/>
                Live fast just for today<br/>
                Tomorrow never comes
                </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
