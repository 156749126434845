import React from 'react';
export const EPKvideo = (props) => {
  return (
    <div id='videos' className='text-center'>
      <div className='container'>
          <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Videos</h2>
        </div>
        <div id='row'> 
            <div className='col-md-12 col-sm-12'>
            <iframe
                class="largevideo"
                title="video1"
                src="https://www.youtube.com/embed/oi7k8oAAWXc"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen>
            </iframe>
            </div>
        </div>
        <div id='row'> 
            <div className='col-md-12 col-sm-12'>
            <iframe
                class="largevideo"
                title="video1"
                src="https://www.youtube.com/embed/QlWqPMR91mU"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen>
            </iframe>
            </div>
        </div>
      </div>
    </div>
  )
}
