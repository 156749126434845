import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import HomePage from './HomePage';
import AboutPage from './AboutPage';
import LwosPage from './LwosPage';
import ComicPage from './ComicPage';
import EPKPage from './EPKPage';
import WolfPage from './WolfPage';
import WavePage from './WavePage';
import TomorrowPage from './TomorrowPage';
import WagePage from './WagePage';
import EmperorPage from './EmperorPage';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <Routes>
        <Route path="/lwos" element={<LwosPage />} />
		    <Route path="/emperor" element={<EmperorPage />} />
        <Route path="/wage" element={<WagePage />} />
        <Route path="/tomorrow" element={<TomorrowPage />} />
        <Route path="/wave" element={<WavePage />} />
        <Route path="/wolf" element={<WolfPage />} />
        <Route path="/band" element={<AboutPage />} />
        <Route path="/comic" element={<ComicPage />} />
        <Route path="/epk" element={<EPKPage />} />
        <Route path="/" element={<HomePage />} />
      </Routes>
    </React.StrictMode>
  </Router>,
  document.getElementById('root')
);

serviceWorker.unregister();
