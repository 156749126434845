import React from 'react';
export const WageLyrics = (props) => {
  return (
    <div id="wavelyrics">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <h2>{props.data ? props.data.title : 'Lyrics'}</h2>
                <p><b>Wage Slave</b></p>
                <p>Hired<br/>
                Stand on your feet<br/>
                Dreaming<br/>
                The willow weeps</p>

                <p>Grind away for another day</p>

                <p>Tired<br/>
                Dragging your feet</p>

                <p>Slave away for another day</p>

                <p>Fired<br/>
                Up from inside<br/>
                Sold away for nothing<br/>
                Just a wage slave</p>

                <p>Trading<br/>
                Life for wages<br/>
                Paying off your cross</p>

                <p>Live<br/>
                To serve their interest<br/>
                Today is not coming back again</p>

                <p>Begging<br/>
                For your days<br/>
                Turned away for nothing<br/>
                Just a wage slave</p>

                <p>Trading<br/>
                Life for wages<br/>
                Paying off your cage</p>

                <p>World we live in</p>

                <p>Wage slave!<br/>
                Tear down the world!<br/>
                Once and for all!<br/>
                Tear down your self!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
