import React from 'react';
export const EPKheader = (props) => {
  return (
    <header id="epkheader">
        <div class="overlay">
            <img src="img/epkcover.jpg" alt="GORO"></img>
            <div class="overlay-text">
                <img src="img/slogold.png" alt="GORO" class="slogold"></img>
                Press Kit
            </div>
        </div>
    </header>
  )
}
