import React from 'react';
export const Bioheader = (props) => {
  return (
    <header id='bioheader'>
      <div className='intro'>
        <div className='overlay'>
              <div className='col-xs-12 col-md-12 col-l-12'>
                <div className='intro-text'>
                    <img
                      src={props.data ? props.data.image : 'img/cover.jpg'}
                      className='img-responsive'
                      alt='GORO'
                    />
                </div>
              </div>
        </div>
      </div>
    </header>
  )
}
