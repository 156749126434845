import React from 'react';
export const EmperorHeader = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
                <div className='video-header-container'>
                    <video className='video-responsive video-header' autoPlay loop muted poster='img/emperorheader.png'>
                      Su navegador no soporta video.
                    </video>
                </div>
        </div>
      </div>
    </header>
  )
}
