import React from 'react';
export const TomorrowLinks = (props) => {
  return (
    <div id='tomorrowmusic' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>{props.data ? props.data.tomorrowTitle : 'Tomorrow Never Comes'}</h2>
        </div>
        <div className='featuresContainer'>
          <div className='col-md-10 col-md-offset-1 col-s-12'>
              <iframe src="https://open.spotify.com/embed/track/1MUWSIbbPCPrPJA4oy23AI?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4 col-md-offset-2'>
              <br/>
              <p>{props.data ? props.data.tomorrowParagraph : "This is a fast-paced, energetic anthem about the value of human connections in life, packed with emotion and energy. Heartfelt lyrics and driving music: this rocking track is short and to the point."}
              </p>
            </div>
          <div className='col-md-4'>
              <div className='social'>
                <ul>
                  <li>
                    <a target='_blank' rel="noreferrer" href={props.data ? props.data.spotify : '/'}>
                      <img src="img/sp_s.png" className="linkIcon" alt="" />{" "}
                        <h3>Spotify</h3>
                    </a>
                  </li>
                  <li>
                    <a target='_blank' rel="noreferrer" href={props.data ? props.data.bandcamp : '/'}>
                      <img src="img/bc_s.png" className="linkIcon" alt="" />{" "}
                        <h3>Bandcamp</h3>
                    </a>
                  </li>
                  <li>
                    <a target='_blank' rel="noreferrer" href={props.data ? props.data.youtube : '/'}>
                      <img src="img/yt_s.png" className="linkIcon" alt="" />{" "}
                        <h3>YouTube</h3>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
