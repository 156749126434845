import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { SubnavTomorrow } from "./components/SubnavTomorrow";
import { TomorrowHeader } from "./components/tomorrowHeader";
import { TomorrowLinks } from "./components/TomorrowLinks";
import { TomorrowLyrics } from "./components/TomorrowLyrics";
import { WolfFeatures } from "./components/WolfFeatures";
import { Newsletter } from "./components/newsletter";
//import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const TomorrowPage = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [currentLanguage, setCurrentLanguage] = useState('en'); // Default language is English
    
  useEffect(() => {
    // Load data based on the current language
    const dataPath = currentLanguage === 'en' ? 'data_en.json' : 'data_es.json';
    import(`./data/${dataPath}`).then((jsonData) => {
      setLandingPageData(jsonData.default);
    });
  }, [currentLanguage]);

  const toggleLanguage = () => {
    setCurrentLanguage(currentLanguage === 'en' ? 'es' : 'en');
  };    
    
  return (
    <div>
      <button onClick={toggleLanguage} className={"languageButton"}>EN / ES</button>
      <Navigation data={landingPageData.Navigation} />
      <SubnavTomorrow />
      <TomorrowHeader data={landingPageData.Header} />
      <TomorrowLinks data={landingPageData.TomorrowLinks} />
      <TomorrowLyrics data={landingPageData.TomorrowLyrics} />
      <WolfFeatures data={landingPageData.WolfFeatures} />
      <Newsletter data={landingPageData.Newsletter} />
    </div>
  );
};

export default TomorrowPage;
