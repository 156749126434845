import React from 'react';
export const EPKbio = (props) => {
  return (
    <div id='epkbio' className='text-center'>
      <div className='container'>
          <div className='col-md-8 col-md-offset-2 section-title'>
          <h2>Biografía</h2>
        </div>
        <div id='row'> 
            <div className='col-md-8 col-sm-12'>
                <h3>Banda</h3>
                <p>Goro emerge en 2020 de la evolución musical, dirigido por la guitarra agresiva y las voces multi dimensionales de Jose Romero.</p>
                <p>Goro se cristaliza alrededor de un trío de luminarias integradas por Guillermo Bolaños, conocido por su fervoroso Death Metal y Grindcore, y Jaime Molina, un pilar de la escena del Metal y Stoner Doom de Ecuador.</p>
                <p>El viaje de Goro se despliega a través de una alquimia audible que se extiende más allá de las cuerdas, infundiendo la identidad de la banda con voces únicas.</p>
                <h3>Integrantes</h3>
                <p>Goro es un poderoso trío musical ecuatoriano liderado por José Romero (Colapso, Punto de Encaje) en guitarra y voz, Guillermo Bolaños (Pille Extremo, Asfixia, Pan con Cloro) en bajo y voz, y Jaime Molina (Damaged Skull, Cafetera Sub, Distorción Social, Superkabras) en batería. </p>
                <p>Estos músicos han sido parte de la escena musical ecuatoriana durante mas de 30 años, compartiendo escenarios con destacadas bandas locales y extranjeras, contribuyendo al auge de la música pesada en Ecuador. 
</p>
            </div>
            <div className='col-md-4 col-sm-12'>
            <div className='image-container'>
              <img src='img/gorex.jpg' alt='Image 1' />
              <div className='text'>José Romero</div>
            </div>

            <div className='image-container'>
              <img src='img/pille.jpg' alt='Image 2' />
              <div className='text'>Guillermo Bolaños</div>
            </div>

            <div className='image-container'>
              <img src='img/detoneidor.jpg' alt='Image 3' />
              <div className='text'>Jaime Molina</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
