import React from 'react';
export const EmperorLinks = (props) => {
  return (
    <div id='emperormusic' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>{props.data ? props.data.wageTitle : 'Emperor Prophet'}</h2>
        </div>
        <div className='featuresContainer'>
          <div className='col-md-10 col-md-offset-1 col-s-12'>
              <iframe src="https://open.spotify.com/embed/track/3Ow8LcEQJ3gpHAuReTkT17?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-4 col-md-offset-2'>
              <br/>
              <p>{props.data ? props.data.wageParagraph : "Welcome the 'Emperor Prophet,' a captivating journey where heavy grooves and epic atmospheres reign supreme. With an unconventional structure and thunderous riffs, this track demands attention from start to finish. Let 'Emperor Prophet' lead you through its enigmatic realm, leaving you spellbound by its mesmerizing soundscapes."}
              </p>
            </div>
          <div className='col-md-4'>
              <div className='social'>
                <ul>
                  <li>
                    <a target='_blank' rel="noreferrer" href={props.data ? props.data.spotify : '/'}>
                      <img src="img/sp_s.png" className="linkIcon" alt="" />{" "}
                        <h3>Spotify</h3>
                    </a>
                  </li>
                  <li>
                    <a target='_blank' rel="noreferrer" href={props.data ? props.data.bandcamp : '/'}>
                      <img src="img/bc_s.png" className="linkIcon" alt="" />{" "}
                        <h3>Bandcamp</h3>
                    </a>
                  </li>
                  <li>
                    <a target='_blank' rel="noreferrer" href={props.data ? props.data.youtube : '/'}>
                      <img src="img/yt_s.png" className="linkIcon" alt="" />{" "}
                        <h3>YouTube</h3>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
