import React from 'react';
export const WaveLyrics = (props) => {
  return (
    <div id="wavelyrics">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-12">
            <div className="about-text">
              <h2>{props.data ? props.data.title : 'Lyrics'}</h2>
                <p><b>Last Wave Of Summer</b></p>
                <p>Your eye can see me everywhere I go<br/>
                No quarter<br/>
                Por favor<br/>
                Let me go</p>

                <p>No more the dreams of what we used to know<br/>
                Gone nowhere<br/>
                But I know<br/>
                That once more<br/>
                We must go</p>

                <p>The fire’s starting to glow<br/>
                Come over<br/>
                The sun is starting to go<br/>
                Last wave of summer</p>

                <p>Fire roaring out the window<br/>
                Spills over<br/>
                Burns the air<br/>
                Up the wall<br/>
                Be no more</p>

                <p>The bomb is ready to blow<br/>
                Waits for nothing<br/>
                Your eyes beginning to glow<br/>
                A deadly warning</p>

                <p>Volcano ready to blow<br/>
                Awaits for nothing<br/>
                The sun is starting to grow<br/>
                Last wave of summer</p>

                <p>Are you ready to go?<br/>
                Are you ready to go?</p>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
