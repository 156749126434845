import React, { useState, useEffect } from 'react';

export const Navigation = (props) => {
  const [isHovered, setIsHovered] = useState(false);
  const [mobileNavOpen, setMobileNavOpen] = useState(false); // Initialize state to false
  const [isMobile, setIsMobile] = useState(false); // Initialize state for mobile device detection

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const toggleMobileNav = () => {
    setMobileNavOpen(!mobileNavOpen); // Toggle mobile navigation visibility
  };

  useEffect(() => {
    // Function to check if the device is a mobile device
    const isMobileDevice = () => {
      return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
    };

    // Initialize mobileNavOpen to true if the device is a mobile device
    if (isMobileDevice()) {
      setMobileNavOpen(true);
      setIsMobile(true); // Set isMobile state to true
    } else {
      setIsMobile(false); // Set isMobile state to false
    }
  }, []);

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top navbarCustom'>
      <div className='container navbarContainer'>
        <div className='navbar-header'>
          <a href='/'>
          <img
            src='img/logoheader.png'
            alt='GORO'
            className='logoImg'
          />
          </a>
          {/* Button to toggle mobile navigation */}
          <button className="navbar-toggler mobile-toggler" onClick={toggleMobileNav}>
            <i className="fa fa-ellipsis-v"></i>
          </button>
        </div>

        {/* Main navigation */}
        {!mobileNavOpen && (
          <div
            className='navbar-collapse'
            id='bs-example-navbar-collapse-1'
          >
            <ul className='nav navbar-nav navbar-left'>
              <li>
                <a 
                  href='/'
                  onMouseEnter={handleMouseEnter}
                >
                  {props.data ? props.data.epLink : 'LWOS EP'}
                </a>
              </li>
              {/*<li>
                <a href='/comic'>{props.data ? props.data.comicLink : 'COMIC'}</a>
              </li>
              <li>
                <a href='/band'>{props.data ? props.data.bandLink : 'BAND'}</a>
              </li>*/}
              <li>
                <a target='blank' href={props.data ? props.data.spotify : '/'}>
                  <i className='fa fa-spotify'></i>
                </a>
              </li>
              <li>
                <a target='blank' href={props.data ? props.data.youtube : '/'}>
                  <i className='fa fa-youtube'></i>
                </a>
              </li>
            </ul>
          </div>
        )}

        {/* Secondary navigation */}
        {!isMobile && isHovered && (
          <div className='container navbarContainer'>
            <div 
              className='secondary-nav navbar-collapse' 
              onMouseLeave={handleMouseLeave}
            >
              {/* Secondary navigation content goes here */}
              <ul className='nav navbar-nav navbar-left'>
                <li><a href='/wave'>{props.data ? props.data.waveLink : 'WAVE'}</a></li>
                <li><a href='/emperor'>{props.data ? props.data.emperorLink : 'EMPEROR'}</a></li>
                <li><a href='/wage'>{props.data ? props.data.wageLink : 'WAGE'}</a></li>
                <li><a href='/tomorrow'>{props.data ? props.data.tomorrowLink : 'TOMORROW'}</a></li>
                <li><a href='/wolf'>{props.data ? props.data.wolfLink : 'WOLF'}</a></li>
              </ul>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};
